/* Styles */
import '@/scss/main.scss'

/* Core */
import Vue from 'vue'
import Buefy from 'buefy'

/* Router & Store */
import router from './router'
import store from './store'

/* Service Worker */
import './registerServiceWorker'

/* Vue. Main component */
import App from './App.vue'




import firebase from "firebase";
// Initialize Firebase
   // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  var firebaseConfig = {
    apiKey: "AIzaSyB8NnbVGeDL55YlYTXKu_mHSi5RwvDhnKE",
    authDomain: "airjson-8f3f8.firebaseapp.com",
    databaseURL: "https://airjson-8f3f8-default-rtdb.firebaseio.com",
    projectId: "airjson-8f3f8",
    storageBucket: "airjson-8f3f8.appspot.com",
    messagingSenderId: "318530409424",
    appId: "1:318530409424:web:f389420fb86ace34a4dfd5",
    measurementId: "G-BB5EN0WPY1"
  };
firebase.initializeApp(firebaseConfig);

Vue.config.productionTip = false;


/* Default title tag */
const defaultDocumentTitle = 'AirJson'

/* Collapse mobile aside menu on route change & set document title from route meta */
router.afterEach(to => {
  store.commit('asideMobileStateToggle', false)

  if (to.meta && to.meta.title) {
    document.title = `${to.meta.title} — ${defaultDocumentTitle}`
  } else {
    document.title = defaultDocumentTitle
  }
})

Vue.config.productionTip = false

Vue.use(Buefy)

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
