function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}



import axios from 'axios';

var cookieName = 'access.rbvmdxnd';
var token = readCookie(cookieName);

var axiosInstance = axios.create({
    baseURL: 'http://localhost:8000/',
    headers:{'Authorization': `bearer ${token}`}
    /* other custom settings */
  });

//axios.defaults.baseURL = 'http://localhost:8000/'
//axios.defaults.headers.common = {'Authorization': `bearer ${token}`}
export default axiosInstance;