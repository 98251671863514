<template>
  <section class="section is-title-bar">
    <div class="level">
      <div class="level-left">
        <div class="level-item">
          <ul>
            <li v-for="(title, index) in titleStack" :key="index">
              {{ title }}
            </li>
          </ul>
        </div>
      </div>
      <div class="level-right">

        <div class="level-item">
          <div class="buttons is-right">
            <a
              href="/upgrade"
              target="_blank"
              class="button is-primary"
            >
              <b-icon icon="credit-card" custom-size="default" />
              <span>Choose your plan</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'TitleBar',
  props: {
    titleStack: {
      type: Array,
      default: () => []
    }
  }
}
</script>
